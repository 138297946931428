<template>
  <div class="antd-table-search-wrap layout-content-page" style="min-height:752px;">
    <div>
    <div class="antd-page-content">
      <a-menu mode="horizontal" v-model="currentMenu">
        <a-menu-item v-for="(item,index) in projectTypeList" :key="index" @click="statusClickHandle(item.id)">{{ item.courseTypeName }}
        </a-menu-item>
      </a-menu>
    </div>
    <a-list item-layout="horizontal" :data-source="listData" :pagination="pagination">
      <a-list-item slot="renderItem" slot-scope="item">
        <div class="list">

            <div class="list-box">
              <div class="video-mark"></div>
              <img :src="$imgBaseUrl + item.courseImageUrl" alt="">
              <p>{{item.courseName}}</p>
                <router-link :to="{path:'/personalDevelopment/course/detail',query:{courseId:item.id,pageNum:pageNum}}" class="list-box-detail">
                </router-link>
                <a-icon type="youtube" class="youtube" />
            </div>
        </div>
      </a-list-item>
    </a-list>
    </div>
    <router-view @refreshDataList="resetTable"></router-view>
  </div>
</template>

<script>
import { getCourseList, getCourseType } from '@/httpsAPI/course/index';
const projectTypeList = [];
export default {
  data () {
    return {
      pageNum:1,
      projectTypeList,
      listData: [],
      currentMenu:[0],
      id:'',
      queryParams:{
        courseTypeId:1
      },
      pagination: {
        total: 0,
        current: 1,
        onChange: page => {
          this.pagination.current = page
          this.loadDeclareeData()
        },
        pageSize: 8,
      },
    }
  },
  created () {
    this.loadCourseType()
  },
  methods: {
      //编辑完返回当前页面
    resetTable(pageNo){ 
      this.pagination.current = Number(pageNo)
      this.loadDeclareeData()
    },
    // 课程列表
    loadDeclareeData () {
      this.pageNum = this.pagination.current
      return getCourseList(Object.assign({ pageNo: this.pagination.current, pageSize: this.pagination.pageSize }, this.queryParams)).then(res => {
        this.listData = res.data.data
        this.pagination.total = res.data.totalCount
      })
    },
    // 课程分类列表
    loadCourseType() {
      getCourseType().then(res => {
          this.projectTypeList = res.data;
          this.projectTypeList.push({courseTypeName: "企业课程", id: 0})

          this.queryParams.courseTypeId = this.projectTypeList[0].id
          this.loadDeclareeData()
        })
    },
    statusClickHandle(val){
      this.queryParams.courseTypeId = val;
       this.pagination= {
        total: 0,
        current: 1,
        onChange: page => {
          this.pagination.current = page
          this.loadDeclareeData()
        },
        pageSize: 8,
      },
      this.loadDeclareeData()
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .ant-list-pagination .ant-pagination{
  padding: 40px 0 0 0 !important;
}
.list-box {
  position: relative;
  cursor: pointer;
  float: left;
  width: 250px;
  height: 200px;
  font-size: 18px;
  font-weight: 600;
  color: #1890ff;
  background-color: #333;
  margin: 30px;
  .youtube{
    position: absolute;
    font-size: 50px;
    top: 80px;
    left: 100px;
    color: #1890ff;
  }
  img{
    width: 100%;
    height: 200px;
  }
  p{
    border-left:5px solid #1890ff ;
    margin-top:5px;
    color: rgb(104, 104, 104);
    font-size: 14px;
    text-indent: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .list-box-detail{
    position: absolute;
    display: inline-block;
    width: 250px;
    height: 200px;
    line-height: 220px;
    background: #000;
    opacity: 0.3;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .list-box-detail:nth-child(2){
    border-right: 1px solid rgb(228, 227, 227);
  }
}
.antd-table-search-wrap {
  min-height: 100%;
  padding-bottom: 20px;
}
.ant-list-split .ant-list-item {
  border: none;
}
.ant-list-something-after-last-item
  .ant-spin-container
  > .ant-list-items
  > .ant-list-item:last-child {
  border: none;
}
.ant-spin-container ul li {
  float: left;
}
/deep/ .ant-list-pagination {
  clear: both;
}
</style>